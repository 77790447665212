.logo {
  background-image: url(../../../asset/image/logo.svg);
  width: 253px;
  height: 58px;
  padding-right: 110px;
  background-repeat: no-repeat;
  @media screen and (max-width: 545px) {
    padding-right: unset;
  }
}

.emailImage {
  background-size: 50px;
  width: 50px;
  height: 50px;
  background-image: url(../../../asset/image/email.svg);
  @media screen and (max-width: 545px) {
    height: 28px;
    width: 28px;
    background-size: 28px;
  }
}
.linkdinImage {
  background-size: 50px;
  width: 50px;
  height: 50px;
  background-image: url(../../../asset/image/linkdin.svg);
  cursor: pointer;
  @media screen and (max-width: 545px) {
    height: 28px;
    width: 28px;
    background-size: 28px;
  }
}
.conditions {
  color: var(--Primary-White, #fff);
  text-align: center;

  font-family: var(--font-400);
  font-size: 16px;

  line-height: 16px;
}
.terms {
  @extend .conditions;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}
.title {
  font-size: 60px;
  font-family: var(--font-800);
  line-height: 60px;
  transition: all 1.5s ease;
  text-align: center;
  @media screen and (max-width: 545px) {
    font-size: 48px;
    line-height: 48.5px;
    width: 342px;
    font-family: var(--font-700);
  }
}

.text {
  //styleName: Neema Global Web/Headline/Meduim;
  font-family: var(--font-400);
  font-size: 32px;

  line-height: 39px;
  width: 620px;

  text-align: center;

  @media screen and (max-width: 545px) {
    font-size: 32px;
    line-height: 39px;
    width: 342px;
    font-size: 20px;
    line-height: 26px;
  }
}

.wrapper {
  background-color: #0a3f48;
  color: white;
  background-image: url(../../../asset/image/green-footer.svg);

  background-size: cover;
  padding: 75px 212px;
  padding-bottom: unset;
  @media screen and (max-width: 545px) {
    background-image: url(../../../asset/image/background/green-footer-mobile.svg);
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 100px;
  }
}

.conditions {
  display: flex;
  flex-direction: row;
  gap: 80px;
  align-items: center;

  @media screen and (max-width: 545px) {
    flex-direction: column;
    gap: 24px;
  }
}

.contact {
  display: flex;
  flex-direction: row;
  gap: 17.86px;
  padding-left: 135px;
  @media screen and (max-width: 545px) {
    padding-left: unset;
  }
}

.bottomWrapper {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  @media screen and (max-width: 545px) {
    flex-direction: column;
    gap: 24px;
    padding-top: unset;
  }
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-bottom: 64px;
}
.contact {
  cursor: pointer;
}
