.title {
  color: var(--Primary-Secondary-Dark-Green, #0a3f48);
  font-family: var(--font-700);
  font-size: 48px;
  font-style: normal;
  line-height: 48.5px;
  text-align: center;
  @media screen and (max-width: 545px) {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 50px;

  @media screen and (max-width: 545px) {
    gap: 22px;
    //  padding-top: 22px;
  }
}

.desktop_image {
  display: none;
  @media screen and (max-width: 545px) {
    display: unset;
  }
}

.mobile_image {
  @media screen and (max-width: 545px) {
    display: none;
  }
}
