.title {
  padding: 8px 24px 8px 24px;
  background-color: #f5f4f8;
  color: #4f24e4;
  font-family: var(--font-600);
  line-height: 28px;
  border-radius: 70px;
  height: min-content;
  margin-bottom: auto;
  font-size: 24px;
  width: fit-content;
}

.text {
  font-family: var(--font-400);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  max-width: 863px;
  text-align: left;
  white-space: break-spaces;
  @media screen and (max-width: 545px) {
    display: none;
    color: var(--Primary-Black, #000);
  }
}

.text_mobile {
  display: none;
  @extend .text;
  @media screen and (max-width: 545px) {
    display: unset;
  }
}
.textWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 545px) {
    flex-direction: column;
    gap: 24px;
  }
}

.wrapper {
  gap: 100px;
  display: flex;
  flex-direction: column;
  padding: 60px 0px 0 0px;
  padding-left: 228px;
  padding-right: 228px;
  @media screen and (max-width: 545px) {
    padding: 60px 24px;
    gap: 40px;
  }
}
