.wrapper {
  display: flex;

  justify-content: space-between;
  align-items: center;
  //align-self: stretch;

  //background: var(--Primary-White, #fff);
  flex-direction: column;
  box-sizing: border-box;
  align-items: baseline;
  //background: var(--Primary-Grey, #f5f4f8);
  width: 350px;
  margin-bottom: -8px;
  @media screen and (max-width: 545px) {
    margin-bottom: unset;
    gap: 4px;
  }
  .errorWrapper {
    height: var(--small-input-height);
    margin-bottom: -32px;

    text-align-last: justify;

    @media screen and (max-width: 545px) {
      margin-bottom: unset;
      height: auto;
      text-align-last: unset;
    }
  }

  &.LARGE_BOX_V1 {
    width: 716px;

    margin-bottom: -9px;
    @media screen and (max-width: 545px) {
      width: 294px;
      margin-bottom: unset;
    }
    .errorWrapper {
      @media screen and (max-width: 545px) {
        margin-bottom: unset;
        height: auto;
      }

      gap: 6px;
    }
  }

  &.IN_LARGE_BOX_ROW {
    margin-bottom: unset;
    margin-bottom: -12px;
    .errorWrapper {
      gap: 6px;
      @media screen and (max-width: 545px) {
        margin-bottom: unset;
        gap: 0px;
        height: auto;
      }
    }
    @media screen and (max-width: 545px) {
      margin-bottom: unset;
    }
  }
  @media screen and (max-width: 545px) {
    width: 294px;
  }
}
.box {
  margin-bottom: 6px;
  border: none;
  margin-top: 4px;
  border-radius: 30px;
  padding: 16px 24px;
  width: 100%;
  box-sizing: border-box;
  height: 58px;
  direction: ltr;
  text-align: justify;
  font-size: 18px;
  font-family: var(--font-600);
  &.LARGE_BOX_V1 {
    height: 94px;
    border-radius: 20px;
  }

  &.error {
    border: var(--error, #d30000) solid 1.5px;
    color: #d30000;
    direction: ltr;
    text-align: justify;
  }

  outline: none;
  --webkit-appearance: none;

  @media screen and (max-width: 545px) {
    margin-bottom: 0px;
    margin-top: 0;
  }
}
input:focus {
  border: #4f24e4 solid;
  outline: none;
  color: var(--Primary-Black, #000);
  color: #4f24e4;
  font-size: 18px;
  font-family: var(--font-600);

  line-height: 24px; /* 133.333% */
}
input {
  font-size: 18px;
  font-family: var(--font-600);
}
textarea {
  font-size: 18px;
  font-family: var(--font-600);
}
textarea:focus {
  border: #4f24e4 solid;
  outline: none;
  color: var(--Primary-Black, #000);

  font-size: 18px;
  font-family: var(--font-600);

  line-height: 24px; /* 133.333% */
}

.errorContextImg {
  display: flex;

  // padding-top: 2.6px;
}

.errorWarning {
  color: var(--error, #d30000);
  text-align: right;

  font-size: 14px;

  font-weight: 500;
  line-height: 17px; /* 121.429% */

  font-family: var(--font-500);
  text-align: left;
  align-items: center;
  white-space: break-spaces;
  @media screen and (max-width: 545px) {
    text-align: left;
    max-width: 208px;
    max-width: 215px;
    text-align: center;
  }
}
.errorWrapper {
  padding-left: 22px;

  display: flex;
  gap: 4px;

  align-items: flex-start;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
