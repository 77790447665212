.title {
  color: #0a3f48;
  font-family: var(--font-800);
  font-size: 60px;
  line-height: 60px;
  max-width: 313px;
  margin-left: var(--animation-margin-right);
  @media screen and (max-width: 545px) {
    display: flex;
    align-self: center;

    font-size: 48px;
    font-family: var(--font-700);
    line-height: 49px;

    text-align: center;
  }
}
.big_title {
  color: #0a3f48;
  font-family: var(--font-700);
  font-size: 60px;
  line-height: 60px;
  text-align: center;

  @media screen and (max-width: 545px) {
    font-size: 48px;

    line-height: 49px;

    text-align: center;
  }
}
.small_text {
  color: #2f2e2e;
  font-family: var(--font-400);
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  @media screen and (max-width: 545px) {
    text-align: center;
  }
}

.small_gap {
  display: flex;
  gap: 24px;
  flex-direction: column;
  @media screen and (max-width: 545px) {
    gap: 16px;
  }
}

.tiny_text {
  font-family: var(--font-400);
  padding-left: 24px;

  font-size: 16px;

  line-height: 20px;
}
