.title {
  color: var(--Primary-Very-Dark-Grey, #2f2e2e);
  text-align: center;
  font-family: var(--font-700);

  font-size: 28px;

  line-height: 37px; /* 132.143% */
  @media screen and (max-width: 545px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.wrapper {
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 40px;
  background: var(--Primary-Grey, #f5f4f8);
  width: fit-content;
  margin-top: 60px;
  width: 1000px;
  width: 1264px;
  box-sizing: border-box;
  @media screen and (max-width: 545px) {
    margin-top: 16px;
    width: 100%;
    padding: 40px 14px;
  }
}

.methodes {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-end;
  @media screen and (max-width: 545px) {
    flex-wrap: wrap;
    column-gap: 12px;
  }
}

.methode {
  display: flex;
  width: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--Primary-Black, #000);

  font-family: var(--font-600);
  font-size: 18px;

  line-height: 26px; /* 144.444% */
  @media screen and (max-width: 545px) {
    font-family: var(--font-400);
    font-size: 16px;
    line-height: 16px;
    width: 140px;
  }
}
.image {
  width: 30px;
}

.methodeWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}
