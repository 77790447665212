.page404Wrapper2 {
  position: relative;
  background-color: #0a3f48;
  //height: 100%;
  height: 100vh;
}
.page404Wrapper {
  position: absolute;
  background-repeat: no-repeat;
  z-index: 1000;
  background-color: #0a3f48;
  height: calc(100vh - 84px);
  background-image: url(../../../asset/image/page404image.svg);
  background-size: cover;
  flex-direction: column;
  display: flex;
  background-position-y: -110px;
  left: auto;
  right: auto;
  height: 100%;
  pointer-events: none;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
  width: 100%;

  background-position-x: 104px;
  background-position-y: -96px;

  @media screen and (max-width: 1824px) {
    zoom: 0.95;
    background-size: 95%;
  }
  @media screen and (max-width: 1785.6px) {
    zoom: 0.93;
    background-size: 95%;
  }
  @media screen and (max-width: 1728px) {
    zoom: 0.9;
    background-size: 95%;
  }
  @media screen and (max-width: 1670.4px) {
    zoom: 0.87;
    background-size: 95%;
  }
  @media screen and (max-width: 1632px) {
    zoom: 0.85;
    background-size: 95%;
  }
  @media screen and (max-width: 1593.6px) {
    zoom: 0.83;
    background-size: 95%;
  }
  @media screen and (max-width: 1555.2px) {
    zoom: 0.81;
    background-size: 95%;
  }
  @media screen and (max-width: 1538px) {
    zoom: 0.8;
    background-size: 95%;
  }
  @media screen and (max-width: 1516.8px) {
    zoom: 0.79;
    background-size: 95%;
  }
  @media screen and (max-width: 1497.6px) {
    zoom: 0.78;
    background-size: 95%;
  }

  @media screen and (max-width: 1478.6px) {
    zoom: 0.77;
    background-size: 95%;
  }
  @media screen and (max-width: 1459px) {
    zoom: 0.76;
  }
  @media screen and (max-width: 1440px) {
    zoom: 0.75;
    background-size: 95%;
  }

  @media screen and (max-width: 1401.6px) {
    zoom: 0.73;
    background-size: 95%;
  }
  @media screen and (max-width: 1382.6px) {
    zoom: 0.72;
    background-size: 95%;
  }
  @media screen and (max-width: 1363px) {
    zoom: 0.71;
  }
  @media screen and (max-width: 1344px) {
    zoom: 0.7;
    background-size: 95%;
  }
  @media screen and (max-width: 1324.8px) {
    zoom: 0.69;
    background-size: 95%;
  }
  @media screen and (max-width: 1286.4px) {
    zoom: 0.67;
    background-size: 95%;
  }
  @media screen and (max-width: 1248px) {
    zoom: 0.65;
    background-size: 95%;
  }
  @media screen and (max-width: 1209.6px) {
    zoom: 0.63;
  }

  @media screen and (max-width: 1228.6px) {
    zoom: 0.64;
  }

  @media screen and (max-width: 1209.6px) {
    zoom: 0.63;
  }
  @media screen and (max-width: 1190.4px) {
    zoom: 0.62;
  }
  @media screen and (max-width: 1152px) {
    zoom: 0.6;
  }
  @media screen and (max-width: 1056px) {
    zoom: 0.55;
  }
  @media screen and (max-width: 962px) {
    zoom: 0.5;
    // background-size: 50%;
  }
  @media screen and (max-width: 867px) {
    zoom: 0.45;
  }
  @media screen and (max-width: 770px) {
    zoom: 0.4;
  }

  @media screen and (max-width: 680px) {
    zoom: 0.35;
  }
  @media screen and (max-width: 578px) {
    zoom: 0.3;
  }
  @media screen and (max-width: 545px) {
    zoom: unset;
  }
  @media screen and (max-width: 545px) {
    background-image: url(../../../asset/image/background/page404Background_mobile.svg);
    background-size: contain;
    background-position: unset;
    height: calc(100vh - 82px);
    background-size: 100%;
    background-position-y: 82px;
  }

  @media screen and (max-width: 390px) {
    zoom: 1;
  }
  @media screen and (max-width: 360px) {
    zoom: 0.95;
    height: calc((100vh / 0.95) - 82px);
  }
  @media screen and (max-width: 351px) {
    zoom: 0.9;
    height: calc((100vh / 0.9) - 82px);
  }
  @media screen and (max-width: 332px) {
    zoom: 0.8;
    height: calc((100vh / 0.8) - 82px);
  }
  @media screen and (max-width: 312px) {
    zoom: 0.75;
    height: calc((100vh / 0.75) - 82px);
  }

  @media screen and (max-width: 273px) {
    zoom: 0.7;
    height: calc((100vh / 0.7) - 82px);
  }
  @media screen and (max-width: 234px) {
    zoom: 0.6;
    height: calc((100vh / 0.6) - 82px);
  }
  @media screen and (max-width: 215px) {
    zoom: 0.5;
  }
  @media screen and (max-width: 200px) {
    zoom: 0.4;
  }
  @media screen and (max-width: 170px) {
    zoom: 0.3;
  }
}

.fullImage {
  position: relative;
  //height: 900px;
  width: 100vw;
  top: -90px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.errorCurrency_desktop {
  width: 100%;

  @media screen and (max-width: 545px) {
    display: none;
    top: unset;
  }
}

.errorCurrency_mobile {
  position: absolute;
  width: 100%;
  pointer-events: none;
  display: none;
  @media screen and (max-width: 545px) {
    display: unset;
    top: 105px;
  }
}

.error {
  position: relative;
  width: 1600px;
}
.aaa {
  height: 100%;
  background-color: #0a3f48;
}
.title {
  font-size: 60px;

  font-family: var(--font-800);
  line-height: 60px;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  @media screen and (max-width: 545px) {
    font-size: 48px;

    font-family: var(--font-700);
    line-height: 48.5px;
    max-width: 300px;
  }
}

.content {
  display: flex;
  flex-direction: column-reverse;
}

.text {
  margin-top: auto;
  margin-bottom: 255px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media screen and (max-height: 950px) {
    margin-bottom: 250px;
  }

  @media screen and (max-height: 950px) and (max-width: 545px) {
    margin-bottom: 150px;
  }

  @media screen and (max-height: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (max-height: 740px) {
    margin-bottom: 200px;
  }
  @media screen and (max-width: 545px) {
    margin-bottom: 107px;
    gap: 42px;
  }
  @media screen and (max-height: 740px) and (max-width: 545px) {
    margin-bottom: 100px;
  }
  @media screen and (max-height: 675px) {
    margin-bottom: 250px;
  }
  @media screen and (max-height: 675px) and (max-width: 545px) {
    margin-bottom: 30px;
  }
  @media screen and (max-height: 630px) {
    margin-bottom: 150px;
  }
  @media screen and (max-height: 630px) and (max-width: 545px) {
    margin-bottom: 20px;
  }
}
