.wrapper {
  display: flex;
  flex-direction: column;
  padding: 154px 0;
  padding-bottom: 92px;
  // gap: 30px;
  padding-top: 200px;
  justify-content: center;
  //height: 100%;
  padding-bottom: 65px;
  // padding-bottom: 80px;
  @media screen and (max-width: 545px) {
    flex-direction: column;
    gap: unset;
    margin: 24px;
    padding-top: 6px;
    padding-bottom: 39px;
  }
}
.ContactUsSuccessWrapper {
  padding-bottom: 92px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 79px;
  margin-top: -4px;

  @media screen and (max-width: 545px) {
    margin: unset;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.ContactUsSuccessTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  // padding-top: 100px;
  opacity: 0;

  @media screen and (max-width: 545px) {
    align-items: center;
  }
}
//
.successSignUpImage {
  //padding-top: 100px;
  opacity: 0;

  width: 215px;
  height: 243px;
  box-sizing: border-box;
  @media screen and (max-width: 545px) {
    width: 167px;
    height: 167px;
  }
  padding-bottom: 28px;
}
.errorT {
  font-family: var(--font-600);
  color: #d30000;
  width: 100%;
  background-color: #ffe5e3;
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  border-radius: 8px;

  bottom: 0;
  width: 354px;
  margin: auto;
  margin-bottom: -70px;

  font-size: 16px;
  height: 48px;
  width: fit-content;
  gap: 42px;
  direction: ltr;
  margin-top: 22px;
  @media screen and (max-width: 545px) {
    margin-top: unset;
    margin-bottom: -51px;
    padding-top: 5px;
    height: 64px;
  }
}

.errorContextImg {
  display: flex;
  &.twoRowsText {
    padding-bottom: 10px;
  }
}
.errorContent {
  display: flex;
  align-items: center;
  gap: 4px;
  @media screen and (max-width: 545px) {
    align-items: self-start;
    text-align: justify;
  }
}

.exitLogo {
  display: flex;
  cursor: pointer;
  height: calc(24px * var(--screen_proportionV2));
}

.coulm_flex {
  display: flex;
  flex-direction: row;
  gap: 383px;
  justify-content: center;

  @media screen and (max-width: 545px) {
    flex-direction: column;
    gap: 45px;
  }
}

.x {
  display: flex;
  cursor: pointer;
}
.text_wrapper {
  // max-width: 208px;
  @media screen and (max-width: 545px) {
    max-width: 208px;
  }
}
