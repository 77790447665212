.button {
  color: var(--Primary-Secondary-Dark-Green, #0a3f48);
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 24px;
  width: fit-content;
  border-radius: 30px;
  background-color: var(--Primary-White, #fff);
  box-sizing: border-box;
  font-family: var(--font-600);
  display: flex;
  align-items: center;
  justify-content: center;
  &.version1 {
    color: #fff;
    background-color: #1f5862;
    &:hover {
      background-color: #0a3f48;
    }
  }
  &.version2 {
    color: var(--Primary-White, #fff);

    font-size: 18px;
    font-style: normal;

    line-height: 24px;
    border-radius: 30px;
    display: flex;
    background: var(--Primary-Purple, #7c57fd);
    &:hover {
      background-color: #6238f6;
    }

    @media screen and (max-width: 545px) {
      line-height: 40px;
      justify-content: center;
    }
  }
  &.version3 {
    background-color: white;
    color: var(--Primary-Purple, #4f24e4);

    border: 1.5px solid var(--Primary-Purple, #7c57fd);
    &:hover {
      background-color: #ece7ff;
    }
  }
  &.version4 {
    background-color: transparent;
    color: white;
    margin: auto;
    border: 1.5px solid white;
    pointer-events: initial;
    &:hover {
      background: white;
      color: var(--Primary-Purple, #4f24e4);
    }
    @media screen and (max-width: 545px) {
      line-height: 24px;
    }
  }
  &.version5 {
    background-color: white;
    color: var(--Primary-Purple, #4f24e4);
    z-index: 200;
    &:hover {
      background-color: #ece7ff;
    }
  }
  &.version_big {
    padding: 16px 79px;
    @media screen and (max-width: 545px) {
      font-family: 18px;
      padding: 8px 24px;
    }
  }

  @media screen and (max-width: 545px) {
    height: 60px;
    width: 100%;
    text-align: center;
    line-height: 40px;
    box-sizing: border-box;
  }
}

.logo {
  background-image: url(../asset/image/logo.svg);
  width: 253px;
  height: 60px;
  cursor: pointer;
  background-repeat: no-repeat;
  padding-right: 95px;

  @media screen and (max-width: 545px) {
    background-image: url(../asset/image/logo_ci.svg);
    padding-right: unset;
    height: 50px;
  }
  &.white {
    background-image: url(../asset/image/logo.svg);
    @media screen and (max-width: 545px) {
      background-image: url(../asset/image/logo_ci.svg);
      height: 50px;
    }
  }
  &.green {
    background-image: url(../asset/image/logo-green.svg);
    @media screen and (max-width: 545px) {
      background-image: url(../asset/image/Logo_green_mobile.svg);
      height: 50px;
    }
  }
}
