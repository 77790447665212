.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #0a3f48;
}

.flex {
  padding: 228px;
  padding-top: 80px;
  padding-bottom: 164px;
}
