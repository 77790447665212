.headerWrapper {
  background-size: cover;
  position: sticky;

  z-index: 1000;
  position: sticky;
  top: 0;
  //transition: background-image 2s;

  transition: unset;

  background-color: initial;
  background-image: url(../../asset/image/background/green.svg);

  color: #fff;
  background-color: #0a3f48;
  background-size: auto;
  background-size: 100%;
  background-size: 100% calc(100% - -853px);
  //background-size: 100% calc(100% + 521px);
  @media screen and (max-width: 545px) {
    background-image: url(../../asset/image/background/green-mobile.svg);
    background-size: cover;
  }

  &.green {
    background-color: #0a3f48;
    background-image: unset;
    height: 92px;
    @media screen and (max-width: 545px) {
      height: 83px;
    }
  }

  &.purpl {
    background-image: url(../../asset/image/background/purple.svg);
    background-color: #7c57fd;
    background-size: 100%;
    @media screen and (max-width: 545px) {
      height: 83px;
      background-image: url(../../asset/image/background/purple_mobile.svg);
    }
  }
  &.white {
    background-color: white;
    background-image: unset;
    background-size: auto;
  }
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  transition: all 1.5s ease;

  @media screen and (max-width: 1824px) {
    zoom: 0.95;
    background-size: 95%;
  }
  @media screen and (max-width: 1785.6px) {
    zoom: 0.93;
    background-size: 95%;
  }
  @media screen and (max-width: 1728px) {
    zoom: 0.9;
    background-size: 95%;
  }
  @media screen and (max-width: 1670.4px) {
    zoom: 0.87;
    background-size: 95%;
  }
  @media screen and (max-width: 1632px) {
    zoom: 0.85;
    background-size: 95%;
  }
  @media screen and (max-width: 1593.6px) {
    zoom: 0.83;
    background-size: 95%;
  }
  @media screen and (max-width: 1555.2px) {
    zoom: 0.81;
    background-size: 95%;
  }
  @media screen and (max-width: 1538px) {
    zoom: 0.8;
    background-size: 95%;
  }
  @media screen and (max-width: 1516.8px) {
    zoom: 0.79;
    background-size: 95%;
  }
  @media screen and (max-width: 1497.6px) {
    zoom: 0.78;
    background-size: 95%;
  }

  @media screen and (max-width: 1478.6px) {
    zoom: 0.77;
    background-size: 95%;
  }
  @media screen and (max-width: 1459px) {
    zoom: 0.76;
  }
  @media screen and (max-width: 1440px) {
    zoom: 0.75;
    background-size: 95%;
  }

  @media screen and (max-width: 1401.6px) {
    zoom: 0.73;
    background-size: 95%;
  }
  @media screen and (max-width: 1382.6px) {
    zoom: 0.72;
    background-size: 95%;
  }
  @media screen and (max-width: 1363px) {
    zoom: 0.71;
  }
  @media screen and (max-width: 1344px) {
    zoom: 0.7;
    background-size: 95%;
  }
  @media screen and (max-width: 1324.8px) {
    zoom: 0.69;
    background-size: 95%;
  }
  @media screen and (max-width: 1286.4px) {
    zoom: 0.67;
    background-size: 95%;
  }
  @media screen and (max-width: 1248px) {
    zoom: 0.65;
    background-size: 95%;
  }
  @media screen and (max-width: 1209.6px) {
    zoom: 0.63;
  }

  @media screen and (max-width: 1228.6px) {
    zoom: 0.64;
  }

  @media screen and (max-width: 1209.6px) {
    zoom: 0.63;
  }
  @media screen and (max-width: 1190.4px) {
    zoom: 0.62;
  }
  @media screen and (max-width: 1152px) {
    zoom: 0.6;
  }
  @media screen and (max-width: 1056px) {
    zoom: 0.55;
  }
  @media screen and (max-width: 962px) {
    zoom: 0.5;
    background-size: 50%;
  }
  @media screen and (max-width: 867px) {
    zoom: 0.45;
  }
  @media screen and (max-width: 770px) {
    zoom: 0.4;
  }

  @media screen and (max-width: 680px) {
    zoom: 0.35;
  }
  @media screen and (max-width: 578px) {
    zoom: 0.3;
  }
  @media screen and (max-width: 545px) {
    zoom: unset;
  }
  @media screen and (max-width: 390px) {
    zoom: 1;
  }
  @media screen and (max-width: 370px) {
    zoom: 0.95;
  }
  @media screen and (max-width: 362.7px) {
    zoom: 0.93;
  }
  @media screen and (max-width: 351px) {
    zoom: 0.9;
  }
  @media screen and (max-width: 331.5px) {
    zoom: 0.85;
  }
  @media screen and (max-width: 332px) {
    zoom: 0.8;
  }
  @media screen and (max-width: 304px) {
    zoom: 0.78;
  }
  @media screen and (max-width: 292px) {
    zoom: 0.75;
  }

  @media screen and (max-width: 273px) {
    zoom: 0.7;
  }
  @media screen and (max-width: 234px) {
    zoom: 0.6;
  }
  @media screen and (max-width: 215px) {
    zoom: 0.5;
  }
  @media screen and (max-width: 200px) {
    zoom: 0.4;
  }
  @media screen and (max-width: 170px) {
    zoom: 0.3;
  }
}
//1920
