.wrapper {
  background-color: #0a3f48;
  padding: 228px;
  padding-top: 20px;
  padding-right: 64px;
  padding-bottom: 164px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: break-spaces;
  justify-content: space-between;
  padding: 20px 64px 130px 120px;
  @media screen and (max-width: 545px) {
    flex-direction: column;
    padding: unset;
  }
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 545px) {
    padding: 60px 24px;
  }
}

.title {
  color: var(--Primary-White, #fff);
  margin-left: var(--animation-margin-right);
  font-family: var(--font-800);
  font-size: 60px;
  font-style: normal;
  text-align: left;
  line-height: 60px;

  @media screen and (max-width: 545px) {
    text-align: center;
    font-size: 48px;
    line-height: 48.5px;
    font-family: var(--font-700);
  }
}

.text {
  display: none;
  @media screen and (max-width: 545px) {
    display: unset;
    text-align: center;
    color: var(--Primary-Grey, #f5f4f8);
    text-align: left;
    font-size: 20px;
    font-style: normal;

    font-family: var(--font-400);
    line-height: 26px; /* 130% */
    max-width: 450px;
    padding-bottom: 12px;
    max-width: 332px;
    text-align: center;
  }
}
.image_mobile {
  display: none;
  @media screen and (max-width: 545px) {
    display: unset;
    width: 100%;
    margin-top: -110px;
  }
}

.image {
  width: 859px;
  height: 469px;
  display: unset;
  @media screen and (max-width: 545px) {
    display: none;
  }
}
.text_desktop {
  color: var(--Primary-Grey, #f5f4f8);
  text-align: left;
  font-size: 20px;
  font-style: normal;

  font-family: var(--font-400);
  line-height: 26px; /* 130% */
  max-width: 438px;
  padding-bottom: 8px;
  @media screen and (max-width: 545px) {
    display: none;
  }
}

/*
 
*/
