.wrapper {
  display: flex;
  flex-direction: column;

  justify-content: center;

  padding-bottom: 100px;
  @media screen and (max-width: 545px) {
    padding-bottom: 50px;
  }
}
