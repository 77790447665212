.title {
  max-width: 516px;

  font-size: 60px;
  font-family: var(--font-800);

  line-height: 60px;
  letter-spacing: 0em;

  color: #0a3f48;
  text-align: left;
  margin-left: var(--animation-margin-right);
  @media screen and (max-width: 545px) {
    color: var(--Primary-Secondary-Dark-Green, #0a3f48);
    text-align: center;

    font-size: 48px;

    font-family: var(--font-700);
    line-height: 48.5px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media screen and (max-width: 545px) {
    flex-direction: column;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 66px;
  padding: 60px 218px;
  justify-content: space-between;
  @media screen and (max-width: 545px) {
    flex-direction: column-reverse;
    padding: 0 24px;
    gap: 32px;
    padding-bottom: 55px;
  }
}

.image {
  opacity: 0;
  width: 735px;
  height: 537px;
  @media screen and (max-width: 545px) {
    width: 331px;

    height: 237px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
