.wrapper {
  padding-top: 50px;
  .title {
    // margin-left: -17px;
  }
  &.animation {
    .title {
      margin-left: 17px;
    }
  }
  .title {
    padding-bottom: 24px;
    font-size: 48px;
    font-family: var(--font-700);

    line-height: 48.5px;
    letter-spacing: 0em;
    text-align: center;
    color: #0a3f48;
    @media screen and (max-width: 545px) {
      font-size: 48px;
      line-height: 48.5px;
      padding-bottom: unset;
      padding-bottom: 24px;
      font-size: 28px;
      line-height: 34px;
    }
  }
  color: black;
  @media screen and (max-width: 545px) {
    padding: 60px 0;
    padding-bottom: 60px;

    padding-right: unset;
    padding-bottom: unset;
  }
}

.ItemWrapper {
  padding: 16px 16px 16px 16px;
  border-radius: 16px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  height: 453px;

  @media screen and (max-width: 545px) {
    height: 319px;
    box-sizing: unset;
  }
  box-sizing: border-box;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  .title {
    padding-bottom: unset;
    font-family: var(--font-700);
    font-size: 28px;
    color: black;
    line-height: 37px;
    @media screen and (max-width: 545px) {
      font-size: 24px;
      line-height: 28px;
      text-align: left;
    }
    @media screen and (max-width: 545px) {
      align-items: flex-start;
      width: 254px;
    }
  }

  .text {
    font-family: var(--font-400);
    font-size: 20px;
    width: 322px;
    line-height: 26px;
    @media screen and (max-width: 545px) {
      color: black;
      width: unset;
      max-width: 254px;
    }
  }
  @media screen and (max-width: 545px) {
    text-align: left;
    height: 164px;

    align-items: baseline;
  }
}
.image {
  background-repeat: no-repeat;
  width: 322px;
  height: 202.43px;
  @media screen and (max-width: 545px) {
    background-size: 254px;
    width: 254px;
    height: 133.43px;
    // margin-top: auto;
    // height: 217.43px;
  }
}
.SecurityComplianceImage {
  @extend .image;

  background-image: url(../../../../asset/image/security.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/security_mobile2.svg);
  }
}

.GreatCovargeImage {
  @extend .image;
  background-image: url(../../../../asset/image/covarge.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/covrage_mobile2.svg);
  }
}

.RealTimeTransfersImage {
  @extend .image;
  background-image: url(../../../../asset/image/realTime.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/real_time2.svg);
  }
}

.RocksolidStabilityImage {
  @extend .image;
  background-image: url(../../../../asset/image/solid.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/solid_mobile2.svg);
  }
}

.whyToChooseNeemaList {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;
  padding-bottom: 24px;

  @media screen and (max-width: 545px) {
    overflow-x: auto;
    width: 100%;
    gap: 8px;
    padding-bottom: unset;
  }
}

.movement_moshe {
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/movment_active.svg);
    width: 4px;
    height: 4px;
    background-repeat: no-repeat;
  }
  &.active {
    background-image: url(../../../../asset/image/movment.svg);
    width: 11px;
    height: 4px;
    background-repeat: no-repeat;
  }
}

.movementImage {
  @media screen and (max-width: 545px) {
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    //  background-repeat: no-repeat;
    // background-image: url(../../../../asset/image/movment2.svg);
    // width: 342px;
    //  height: 4px;

    justify-content: center;
    padding-bottom: 24px;
    padding-top: 24px;
  }
  @media screen and (min-width: 546px) {
    display: none;
  }
}
