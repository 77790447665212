.mobile_menu_Image {
  @media screen and (max-width: 545px) {
    background-image: url(../../../asset/image/menu_activs.svg);
    width: 42px;
    height: 42px;
  }
}

.page {
  text-align: center;
  color: Black;
  font-family: var(--font-400);

  font-size: 20px;
  cursor: pointer;

  line-height: 26px;

  &.extended_page {
    align-items: center;
    display: flex;
    gap: 4px;

    &:after {
      //height: 18px;
      // content: url(../../../asset/image/chevron.svg);
    }
  }

  &.extended_page_black {
    align-items: center;
    display: flex;
    gap: 4px;

    &:after {
      //  height: 18px;
      //  content: url(../../../asset/image/chevro_black.svg);
    }
  }
}

.menu {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  // padding-left: 30px;

  @media screen and (max-width: 545px) {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  padding: 16px 40px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 545px) {
    padding: 16px 24px;
    height: 82px;
    box-sizing: border-box;
  }
  &.transperent {
    .login {
      color: white;
      @media screen and (max-width: 545px) {
        display: none;
      }
    }
    .menu {
      color: white;
      @media screen and (max-width: 545px) {
        display: none;
      }
    }
    .page {
      color: white;
      &:hover {
        color: #1fed7c;
        border-bottom: #1fed7c 1px solid;
      }
    }
  }

  .mobile_menu_Image {
    @media screen and (max-width: 545px) {
      background-image: url(../../../asset/image/menu_inactive.svg);
      width: 42px;
      height: 42px;
    }
  }

  &.white {
    .page {
      color: black;
      &:hover {
        border-bottom: #4f24e4 1px solid;
        color: #4f24e4;
      }
    }
    .login {
      color: black;
      &:hover {
        border-bottom: #4f24e4 1px solid;
        color: #4f24e4;
      }
      @media screen and (max-width: 545px) {
        display: none;
      }
    }
    .menu {
      color: black;
      @media screen and (max-width: 545px) {
        display: none;
      }
    }
    .mobile_menu_Image {
      @media screen and (max-width: 545px) {
        background-image: url(../../../asset/image/menu_inactive_black.svg);
        width: 42px;
        height: 42px;
      }
    }

    &.extended_page {
      &:after {
        content: url(../../../asset/image/chevro_black.svg);
      }
    }
  }
  &.purpl {
    .login {
      color: white;
      &:hover {
        border-bottom: white 1px solid;
      }
      @media screen and (max-width: 545px) {
        display: none;
      }
    }
    .menu {
      color: white;
      @media screen and (max-width: 545px) {
        display: none;
      }
    }
    .page {
      color: white;
      &:hover {
        border-bottom: white 1px solid;
      }
    }
  }
  .webVersionMenu {
    .mobile_menu_Image {
      @media screen and (max-width: 545px) {
        background-image: url(../../../asset/image/Icon_mobile_exit.svg);
        &.menu_image {
          background-image: url(../../../asset/image/error_exit.svg);
        }
      }
    }
  }
  &.green {
    .mobile_menu_Image {
      @media screen and (max-width: 545px) {
        background-image: url(../../../asset/image/movment_active_white.svg);
      }

      &.menu_image {
        background-image: url(../../../asset/image/Icon_mobile_exit.svg);
      }
    }

    .login {
      color: white;
      &:hover {
        color: #1fed7c;
        border-bottom: #1fed7c 1px solid;
      }
      @media screen and (max-width: 545px) {
        display: none;
      }
    }
    .menu {
      color: white;
      @media screen and (max-width: 545px) {
        display: none;
      }
    }
    .page {
      color: white;
      &:hover {
        color: #1fed7c;
        border-bottom: #1fed7c 1px solid;
      }
    }
  }
}
.mobile_menu_Image {
  &.menu_image {
    background-image: url(../../../asset/image/error_exit.svg);
  }
}
.buttonWrapper {
  @media screen and (max-width: 545px) {
    display: none;
  }
}
.login {
  cursor: pointer;
  color: black;
  text-align: center;
  align-items: center;
  font-family: var(--font-400);
  font-size: 20px;

  line-height: 26px; /* 130% */

  @media screen and (max-width: 545px) {
    display: none;
  }
}
.firstSide {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 545px) {
    gap: 30px;
  }
}
.secondSide {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  gap: 16px;
}
