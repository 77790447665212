.wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;
  transition: all 1.5s ease;
  @media screen and (max-width: 545px) {
    padding: 50px 24px;
  }
}

.flex {
  padding: 228px;
  padding-top: 80px;
  padding-bottom: 164px;
}

.continents {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  @media screen and (max-width: 545px) {
    max-width: 382px;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.continent {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 710px;
  border: 1px solid var(--Primary-Blue-Grey, #c9cee0);
  color: var(--Primary-Secondary-Dark-Green, #0a3f48);
  text-align: center;

  font-size: 32px;
  font-family: var(--font-600);
  line-height: 38px; /* 118.75% */
  box-sizing: border-box;
  cursor: pointer;

  &.active {
    color: white;
    background-color: #0a3f48;
    cursor: default;
  }

  @media screen and (max-width: 545px) {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 710px;
    border: 1px solid var(--Primary-Blue-Grey, #c9cee0);
    color: var(--Primary-Secondary-Dark-Green, #0a3f48);
    text-align: center;
    padding: 8px 14px;

    font-size: 24px;

    line-height: 28px; /* 116.667% */
  }
}

.title {
  color: var(--Primary-Secondary-Dark-Green, #0a3f48);
  text-align: center;
  font-family: var(--font-800);

  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48.5px;
  padding-bottom: 32px;
  transition: all 1.5s ease;
  margin-left: var(--animation-margin-right);
  @media screen and (max-width: 545px) {
    font-size: 28px;

    font-family: var(--font-700);
    line-height: 34px;
  }
}

.country_image {
  width: 24px;
  height: 24px;
  @media screen and (max-width: 545px) {
    width: 18px;
    height: 18px;
  }
}

.countryWrapper {
  display: flex;
  cursor: pointer;
  flex-direction: row-reverse;

  &.active {
    background-color: #f5f4f8;
    border-radius: 48px;

    background: var(--Primary-Grey, #f5f4f8);
  }

  &.next_row_closest_country {
    @media screen and (min-width: 545px) {
      border: none;
    }
  }
}

.countryName {
  color: var(--Primary-Black, #000);

  font-family: var(--font-600);
  font-size: 24px;

  padding-right: 13px;
  line-height: 21px;
  @media screen and (max-width: 545px) {
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    line-height: 28px; /* 116.667% */
    padding-right: unset;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;
  }
}

.countriesWrapper {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 40px;
  padding-left: 228px;
  padding-right: 228px;
  padding-top: 30px;
  @media screen and (max-width: 545px) {
    padding-left: unset;
    grid-auto-rows: max-content;
    padding-right: unset;
    grid-template-columns: repeat(2, auto);
    width: auto;
  }
}

.countriesWrapper:nth-child(2n + 1):last-child {
  @media screen and (max-width: 545px) {
    margin-left: auto;
  }
}
.countryWrapper:nth-child(2n + 2) {
  @media screen and (max-width: 545px) {
    margin-left: auto;
  }
}
.countryWrapper {
  height: 70px;
  box-sizing: border-box;
  gap: 8px;
  display: flex;
  flex-direction: row-reverse;
  padding: 22px 24px;
  justify-content: flex-end;
  //border-top: #c9cee0 0.5px solid;
  padding-left: 24px;
  @media screen and (min-width: 546px) {
    align-items: center;
    &.first_row {
      border-top: none;
    }
  }
  &.active {
    @media screen and (max-width: 545px) {
      padding: 8px 10px;
      padding-left: 24px;
      padding-right: unset;
      padding-left: 13px;
      align-items: center;
      // border-bottom: black 6.5px solid;
    }

    border: 1.5px solid var(--Primary-Secondary-Dark-Green, #0a3f48);
    box-sizing: border-box;
  }
  @media screen and (max-width: 545px) {
    padding: 8px 12px;
    width: 155px;
    box-sizing: border-box;
    align-items: center;

    &.first_row_mobile {
      border-top: none;
    }
    &.active {
      align-items: center;
      border-top: #0a3f48 0.5px solid;
    }
  }
}

.error_text_title {
  color: var(--Primary-Very-Dark-Grey, #2f2e2e);
  text-align: center;

  font-family: var(--font-600);
  font-size: 24px;
  font-style: normal;

  line-height: 28px; /* 116.667% */
  padding-top: 9px;
}

.error_text {
  color: var(--Primary-Very-Dark-Grey, #2f2e2e);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-family: var(--font-400);
  line-height: 20px; /* 125% */
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 60px;
  align-items: center;
  padding-bottom: 30px;

  &::before {
    content: url(../../../asset/image/refresh.svg);
  }
}

.divWrapper {
  margin-right: 52px;
}
