.title {
  color: var(--Primary-Secondary-Dark-Green, #0a3f48);
  padding-bottom: 40px;
  font-size: 48px;
  transition: all 1.5s ease;
  line-height: 48.5px;
  font-family: var(--font-700);
  text-align: left;
  transition: all 1.5s ease;
  &.main_top {
    margin-left: var(--animation-margin-right);
  }
  @media screen and (max-width: 545px) {
    line-height: 34px;
    font-size: 28px;
    padding-bottom: 24px;
    margin-left: 0px;
    &.main_top {
      margin-left: 24px;
    }
    &.top {
      margin-left: 24px;
    }

    //
  }
}

.ourTeam {
  .title {
    color: var(--Primary-Very-Dark-Grey, #2f2e2e);
    text-align: right;
    font-family: var(--font-600);
    padding-bottom: 12px;
    font-size: 32px;
    text-align: left;
    line-height: 38px;

    color: var(--Primary-Very-Dark-Grey, #2f2e2e);
    @media screen and (max-width: 545px) {
      font-size: 24px;
      line-height: 28px;
      padding-bottom: unset;

      //line-height: 48px;
    }
  }
}
.movmentSide {
  display: flex;
  flex-direction: row-reverse;
  @media screen and (max-width: 545px) {
    height: fit-content;
  }
}
.image {
  background-repeat: no-repeat;
  width: 158px;
  height: 158px;
  padding-bottom: 21px;
  @media screen and (max-width: 545px) {
    height: 120px;
    width: 120px;
    padding-bottom: 16px;
  }
}
.movmentRightSide {
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/right-active.svg);
    width: 50px;
    height: 50px;
  }
  &.disable {
    background-image: url(../../../../asset/image/left-inactive.svg);
    transform: rotate(180deg);
  }
}

.movement_moshe {
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/movment_active.svg);
    width: 4px;
    height: 4px;
    background-repeat: no-repeat;
  }
  &.active {
    background-image: url(../../../../asset/image/movment.svg);
    width: 11px;
    height: 4px;
    background-repeat: no-repeat;
  }
}

.movmentLeftSide {
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/right-active.svg);
    width: 50px;
    height: 50px;
    transform: rotate(180deg);
  }
  &.disable {
    background-image: url(../../../../asset/image/left-inactive.svg);
    transform: unset;
  }
}
.doronImage {
  @extend .image;

  background-image: url(../../../../asset/image/doron.svg);

  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/doron_mobile.svg);
  }
}
.mosheImage {
  @extend .image;

  background-image: url(../../../../asset/image/moshe.svg);
  @media screen and (max-width: 545px) {
    height: 120px;
    width: 120px;
    background-image: url(../../../../asset/image/mosh_mobile.svg);
  }
}
.yuvalImage {
  @extend .image;

  background-image: url(../../../../asset/image/yuval.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/yuval_mobile.svg);
  }
}
.asafImage {
  @extend .image;

  background-image: url(../../../../asset/image/asaf.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/asaf_mobile.svg);
  }
}
.shiraImage {
  @extend .image;

  background-image: url(../../../../asset/image/shira.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/shira_mobile.svg);
  }
}
.investoreImage {
  background-repeat: no-repeat;
  width: 76px;
  height: 76px;
  padding-bottom: 12px;
}

.hapoalimeImage {
  @extend .investoreImage;
  background-image: url(../../../../asset/image/hapoalim.svg);
}
.directImage {
  @extend .investoreImage;
  background-image: url(../../../../asset/image/direct.svg);
}

.discountImage {
  @extend .investoreImage;
  background-image: url(../../../../asset/image/discount.svg);
}

.OurTeamWrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  transition: all 1.5s ease;
  @media screen and (max-width: 545px) {
    overflow-x: auto;
    padding-left: 24px;
    overflow-y: hidden;

    // width: calc(100vw - 22px);
  }
}

.teamMember {
  width: 273.6px;
  height: 458px;
  padding: 16px;
  border-radius: 20px;
  background-color: #f5f4f8;
  text-align: left;
  box-sizing: border-box;
  width: 273px;
  height: 420px;
  @media screen and (max-width: 545px) {
    padding: 16px;
    height: 388px;
  }

  .title {
    color: var(--Primary-Black, #000);
    text-align: center;
    text-align: left;
    font-size: 24px;

    font-family: var(--font-600);

    line-height: 28px;
    padding-bottom: 2px;
    @media screen and (max-width: 545px) {
      font-size: 18px;
    }
  }

  .text {
    color: var(--Primary-Black, #000);
    text-align: center;
    font-family: var(--font-400);
    text-align: left;
    font-size: 18px;

    line-height: 24px;
  }
  .subText {
    color: var(--Primary-Black, #000);
    text-align: left;
    font-size: 16px;
    padding-top: 21px;
    font-family: var(--font-400);

    line-height: 22px;
    white-space: break-spaces;
    width: 241px;
    @media screen and (max-width: 545px) {
      width: 250px;
      padding-top: 8px;
      width: 200px;
      padding-top: 8px;
      //  width: 196px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.investorMember {
  .title {
    color: var(--Primary-Very-Dark-Grey, #2f2e2e);
    text-align: right;
    font-family: var(--font-600);

    font-size: 18px;

    line-height: 26px;
  }
  .text {
    color: var(--Primary-Very-Dark-Grey, #2f2e2e);
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-family: var(--font-400);

    line-height: 22px;
  }
}

.investorsWrapper {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  @media screen and (max-width: 545px) {
    flex-direction: column;
    align-items: self-start;
  }
}

.ourInvestorsWrapper {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding-bottom: 54px;
  @media screen and (max-width: 545px) {
    padding-bottom: 50px;
    padding-left: 24px;
  }
  .textWrapper {
    .title {
      color: var(--Primary-Very-Dark-Grey, #2f2e2e);
      font-family: var(--font-600);

      font-size: 32px;

      font-weight: 600;
      line-height: 38px;
      text-align: left;
      padding-bottom: 12px;

      @media screen and (max-width: 545px) {
        line-height: 28px;
        font-size: 24px;
      }
    }
    .text {
      color: var(--Primary-Black, #000);
      font-family: var(--font-400);

      font-size: 20px;

      font-weight: 400;
      line-height: 26px;
      text-align: left;
      width: 525px;
      white-space: break-spaces;

      @media screen and (max-width: 545px) {
        width: 345px;
      }
    }
  }
  .investorMember {
    .textWrapper {
      gap: 4px;
      display: flex;
      flex-direction: column;
    }
    .title {
      color: var(--Primary-Very-Dark-Grey, #2f2e2e);
      text-align: right;
      font-family: var(--font-600);

      font-size: 18px;
      text-align: left;
      line-height: 26px;
      padding-bottom: 0;
    }
    .text {
      white-space: break-spaces;
      width: max-content;
      color: var(--Primary-Very-Dark-Grey, #2f2e2e);
      text-align: center;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-family: var(--font-400);
      width: fit-content;
      line-height: 22px;
      @media screen and (max-width: 545px) {
        min-width: 134px;
      }
    }
  }
  @media screen and (max-width: 545px) {
    flex-direction: column;
    gap: 32px;

    // max-width: 166px;
  }
}
.flex {
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media screen and (max-width: 545px) {
    max-width: 352px;
  }
}
.wrapper {
  // padding: 227px;
  padding: 228px;
  padding-top: 100px;
  padding-bottom: 50px;
  @media screen and (max-width: 545px) {
    padding-left: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-top: 50px;
  }
}

.linkdinImage {
  width: 28px;
  height: 28px;
  margin-left: auto;
  background-image: url(../../../../asset/image/linkdin-black.svg);
  background-color: white;
  border-radius: 64px;
  cursor: pointer;
}

.text_linkdin_wrapper {
  display: flex;
  justify-content: left;
  flex-direction: row-reverse;
  font-family: var(--font-400);
  font-size: 18px;
  line-height: 24px;
  @media screen and (max-width: 545px) {
    font-size: 16px;
    line-height: 16px;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 545px) {
    justify-content: space-between;
    padding-bottom: 12px;
  }
}

.movementImage {
  display: none;
  @media screen and (max-width: 545px) {
    display: unset;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    //  background-repeat: no-repeat;
    // background-image: url(../../../../asset/image/movment2.svg);
    // width: 342px;
    //  height: 4px;
    margin-top: 12px;
    justify-content: center;
  }
}
