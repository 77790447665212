body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --Primary-White: #fff;
  --small-input-height: 32px;
  --font-400: ploni-regular;
  --font-500: ploni-medium;
  --font-600: ploni-demibold;
  --font-700: ploni-bold;
  --font-800: ploni-ultrabold;
  --animation-margin-right: -35px;
  --Primary-Secondary-Dark-Green: #0a3f48;
}

@font-face {
  font-family: ploni-regular;
  src: url("asset/font/ploni-regular.woff2") format("woff2"),
    url("asset/font/ploni-regular.woff") format("woff");
}
@font-face {
  font-family: ploni-demibold;
  src: url("asset/font/ploni-demibold.woff2") format("woff2"),
    url("asset/font/ploni-demibold.woff") format("woff");
}
@font-face {
  font-family: ploni-medium;
  src: url("asset/font/ploni-medium.woff2") format("woff2"),
    url("asset/font/ploni-medium.woff") format("woff");
}
@font-face {
  font-family: ploni-bold;
  src: url("asset/font/ploni-bold.woff") format("woff2"),
    url("asset/font/ploni-bold.woff2") format("woff");
}
@font-face {
  font-family: ploni-ultrabold;
  src: url("asset/font/ploni-ultrabold-aaa.woff") format("woff"),
    url("asset/font/ploni-ultrabold-aaa.woff2") format("woff2");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
