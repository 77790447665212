.wrapper {
  background-color: #7c57fd;
  background-image: url(../../../../asset/image/background/purple.svg);
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 64px 74px 64px 74px;
  border-radius: 0px 0px 60px 60px;
  background-position-y: -85px;
  background-position-y: -91.99px;

  background-repeat: no-repeat;
  background-size: 100%;
  @media screen and (max-width: 545px) {
    padding: 24px;
    padding-top: 81px;
    padding-bottom: 61px;
    background-image: url(../../../../asset/image/background/purple_mobile.svg);
  }
}

.title {
  color: white;
  //styleName: Neema Global Web/Display/Large;
  font-family: var(--font-700);
  font-size: 78px;

  line-height: 76px;
  max-width: 790px;
  text-align: center;
  margin-left: var(--animation-margin-right);
  @media screen and (max-width: 545px) {
    font-size: 48px;

    line-height: 48.5px;
    text-align: left;
    text-align: center;
    max-width: 300px;
  }
}

.text {
  color: white;
  font-family: var(--font-400);
  font-size: 20px;
  text-align: center;
  line-height: 26px;
}
