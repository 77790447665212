.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: auto;

  @media screen and (max-width: 545px) {
    gap: 64px;
    width: 100%;
  }
  .title {
    margin-left: var(--animation-margin-right);
  }

  .title {
    color: var(--Primary-White, #fff);
    text-align: center;
    font-family: var(--font-800);

    font-size: 60px;
    line-height: 60px;
    width: 612px;

    transition: all 1.5s ease;

    @media screen and (max-width: 545px) {
      width: 342px;
      font-size: 48px;
      line-height: 48.5px;
      font-family: var(--font-700);
    }
  }
  .button {
    color: var(--Primary-Secondary-Dark-Green, #0a3f48);
    font-family: Ploni 1.1 AAA;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 24px;
    width: 136px;
    border-radius: 30px;
    background-color: var(--Primary-White, #fff);
    box-sizing: border-box;
    font-family: var(--font-600);
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
  }
  padding: 31.5px 228px 83px 228px;
  stroke-width: 328px;
  stroke: #124952;
  background-color: #0a3f48;
  background-image: url(../../../../asset/image/background/green.svg);
  background-position-y: -87px;

  background-repeat: no-repeat;
  background-size: 100% calc(100% + 319px);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/background/green-mobile.svg);
    padding: 31px 0 42px 0;
    background-size: cover;
    background-position-y: -91px;
  }
}

.neemaAmountInfo {
  display: flex;
  flex-direction: row;
  gap: 68px;
  border-radius: 60px;
  background: var(--Primary-Secondary-Dark-Green-2, #1f5862);
  padding: 80px 74px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: fit-content;
  box-sizing: border-box;
  @media screen and (max-width: 545px) {
    max-width: 627px;
    flex-wrap: wrap;
    padding: 50px 10px;
    gap: 20px;
    max-width: 342px;
    max-height: 304px;
  }
}

.neemaAmountWrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
  @media screen and (max-width: 545px) {
    width: 149px;
    align-items: center;
  }
  .amount {
    color: #1fed7c;
    color: var(--Primary-Secondary-Green, #1fed7c);
    font-family: var(--font-700);
    font-size: 72px;
    font-style: normal;

    line-height: 87.478%;
    padding-bottom: 6px;

    @media screen and (max-width: 545px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .amountText {
    color: var(--Primary-Grey, #f5f4f8);
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-family: var(--font-400);
    line-height: 26px;
    white-space: break-spaces;
    @media screen and (max-width: 545px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
