.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
  border: 2px solid white;
  border-top-color: #a6a6ae;
  border-right-color: #a6a6ae;
  border-bottom-color: #a6a6ae;
  border-radius: 50%;
  animation: 1s loader linear infinite;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  margin-right: -52px;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
