.page {
  color: var(--Primary-White, #fff);
  text-align: center;
  text-align: justify;
  font-size: 20px;

  font-family: var(--font-400);

  line-height: 26px; /* 130% */
  padding-top: 26px;
  padding-bottom: 26px;
  border-top: 0.5px solid rgba(143, 172, 177, 0.6);
}

.wrapper {
  background-color: #0a3f48;
  padding: 24px;
  box-sizing: border-box;
  height: calc(100vh - 82px);
  overflow-y: hidden;

  @media screen and (max-width: 360px) {
    height: calc((100vh / 0.95) - 82px);
  }
  @media screen and (max-width: 351px) {
    height: calc((100vh / 0.9) - 82px);
  }
  @media screen and (max-width: 332px) {
    height: calc((100vh / 0.8) - 82px);
  }
  @media screen and (max-width: 312px) {
    height: calc((100vh / 0.75) - 82px);
  }

  @media screen and (max-width: 273px) {
    height: calc((100vh / 0.7) - 82px);
  }
  @media screen and (max-width: 234px) {
    height: calc((100vh / 0.6) - 82px);
  }
}

.menu {
  display: flex;
  flex-direction: column;
}
.page:first-child {
  border-top: none;
}
