.image {
  background-repeat: no-repeat;
  width: 1500px;
  height: 618px;
}
.payoutPortalImage {
  transition: all 1.5s ease;
  @extend .image;
  margin-top: 64px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 65%, #f5f4f8),
    url(../../../../asset/image/portal2.svg);
  background-repeat: no-repeat;
  height: 555px;
  @media screen and (max-width: 545px) {
    width: 342px;
    height: 179px;
    background-size: 342px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 65%, #f5f4f8),
      url(../../../../asset/image/portal_mobil2.svg);
    background-image: url(../../../../asset/image/portal_mobil2.svg);
    background-size: 342px;
    background-repeat: no-repeat;
    //height: 136px;
    /* background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 65%,
        transparent
      ),
      url(../../../../asset/image/payoutPortal.svg);*/
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  //margin: auto;
  align-items: center;
  padding-bottom: 53px;
  background-color: #f5f4f8;

  @media screen and (max-width: 545px) {
    padding: 100px 24px 0px 24px;
    padding-top: 50px;
  }
}

.title {
  color: var(--Primary-Secondary-Dark-Green, #0a3f48);
  text-align: center;

  font-family: var(--font-700);
  font-family: Ploni 1.1 AAA;
  font-size: 48px;

  line-height: 48.5px;
  padding-top: 100px;
  padding-bottom: 24px;
  transition: all 1.5s ease;
  margin-left: var(--animation-margin-right);
  @media screen and (max-width: 545px) {
    font-size: 48px;
    line-height: 34px;
    max-width: 342px;
    padding-top: unset;
    font-size: 28px;
    font-family: var(--font-700);
  }
}

.text {
  padding-bottom: 32px;
  color: var(--Primary-Black, #000);
  text-align: center;

  font-family: var(--font-400);
  font-size: 20px;

  line-height: 26px; /* 130% */
  max-width: 450px;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemWrapper {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.extraElementWrapper {
  margin-top: 64px;
}

.textbold {
  font-family: var(--font-700);
  font-size: 20px;
}

.textW {
  display: flex;
  flex-direction: row;
  gap: 5px;
  @media screen and (max-width: 545px) {
    flex-direction: column;
  }
}

.textTop {
  color: var(--Primary-Black, #000);
  text-align: center;

  font-family: var(--font-400);
  font-size: 20px;

  line-height: 26px; /* 130% */
}

.subTextWrapper {
  grid-template-areas:
    "our_to our_to"
    "watch_the status_transfer"
    "in_time in_time";
  justify-items: unset;
  padding-bottom: 32px;
  text-align: center;
  @media screen and (min-width: 546px) {
    display: none;
  }
}

.row_flex {
  display: flex;
  flex-direction: row;
}
.subTextWrapper_desktop {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  @media screen and (max-width: 545px) {
    display: none;
  }
}
.in_time {
  grid-area: in_time;
  @extend .textbold;
  padding-left: 9px;
}
.status_transfer {
  @extend .textTop;
  grid-area: transfer;
}
.our_to {
  @extend .textTop;
  grid-area: our_to;
  white-space: nowrap;
  justify-self: baseline;
}
.watch_the {
  @extend .textTop;
  grid-area: watch_the;
  justify-self: baseline;
  padding-left: 5px;
  @media screen and (max-width: 545px) {
    padding-left: 18px;
    justify-self: unset;
    padding-left: unset;
  }
}
.status_transfer {
  @extend .textTop;
  grid-area: status_transfer;
  width: fit-content;
  padding-left: 52px;
  @media screen and (max-width: 545px) {
    padding-right: 5px;
  }
}
