.image {
  background-repeat: no-repeat;
  width: 534px;
  height: 305px;
  @media screen and (max-width: 545px) {
    width: 342px;
    height: 189px;
    background-size: 342px;
  }
}
.overNightImage {
  @extend .image;

  background-image: url(../../../../asset/image/over_night.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/over_night-mobile.svg);
    margin-top: 30px;
  }
}

.neverDownImage {
  @extend .image;
  background-image: url(../../../../asset/image/never_down.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/aml_mobile.svg);
    margin-top: 30px;
  }
}

.countlessPossibilitiesImage {
  @extend .image;
  background-image: url(../../../../asset/image/countless_possibilities.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/countless_mobile.svg);
  }
}

.amlImage {
  @extend .image;
  background-image: url(../../../../asset/image/aml.svg);
  @media screen and (max-width: 545px) {
    background-image: url(../../../../asset/image/never-down_mobile.svg);
  }
}

.itemWrapper {
  display: flex;

  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
  gap: 66px;
  justify-content: space-between;
  transition: all 1.5s ease;
  @media screen and (max-width: 545px) {
    flex-direction: column;
    padding-bottom: 50px;
    gap: 38px;

    padding-top: unset;
    &.large {
      padding-bottom: 38px;
    }
  }
}
.sideWrapper {
  width: 645px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.large {
    width: 823px;
  }
  @media screen and (max-width: 545px) {
    max-width: 342px;
  }
}
.title {
  color: var(--Primary-Secondary-Dark-Green, #0a3f48);
  font-family: var(--font-700);

  font-size: 48px;

  line-height: 48.5px;
  max-width: 576px;
  @media screen and (max-width: 545px) {
    font-size: 28px;

    line-height: 34px;
    max-width: 342px;
  }
  @media screen and (max-width: 545px) {
    max-width: 342px;
  }
}

.text {
  color: var(--Primary-Black, #000);

  font-family: var(--font-400);

  font-size: 20px;

  font-weight: 400;
  line-height: 26px;

  max-width: 462.18px;

  &.large {
    max-width: 610px;
  }
  @media screen and (max-width: 545px) {
    font-size: 20px;

    line-height: 26px;
    max-width: 342px;
  }
}

.list {
  gap: 100px;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 50px;
  padding-right: 228px;
  padding-left: 228px;
  @media screen and (max-width: 545px) {
    padding-left: unset;
    padding-right: unset;
    gap: 30px;
    padding-bottom: 30px;
    padding-top: unset;
  }
}

.textWrapper {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 545px) {
    gap: 16px;
  }
}

.itemWrapper:nth-child(odd) {
  flex-direction: row;
  @media screen and (max-width: 545px) {
    flex-direction: column;
  }
}

.country {
  color: var(--Primary-Secondary-Dark-Green, #0a3f48);
  text-align: center;

  font-family: var(--font-600);
  font-size: 18px;
  font-style: normal;

  line-height: 26px;
  border-radius: 710px;
  background: var(--Primary-Grey, #f5f4f8);
  display: flex;
  padding: 6px 12px;
  align-items: flex-start;
  gap: 10px;

  @media screen and (max-width: 545px) {
    padding: 16px;
    line-height: 28px;
    font-size: 24px;
  }
}
.countries {
  width: 425px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: 748px;
  @media screen and (max-width: 545px) {
    gap: 7px;
    width: 345px;
  }
}

.extraElementWrapper {
  gap: 32px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 545px) {
    gap: 48px;
  }
}

.wrapper {
  padding-top: 50px;
  @media screen and (max-width: 545px) {
    padding-top: 30px;
  }
}
