.normal_input_varent {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-row-gap: 34px;
  grid-column-gap: 16px;
  @media screen and (max-width: 545px) {
    grid-template-columns: repeat(1, auto);
    align-items: center;
    justify-content: center;
    grid-row-gap: 28px;
    grid-row-gap: 16px;
  }
}
.loadWrapper {
  display: flex;
  gap: 28px;
  padding-left: 22px;

  @media screen and (max-width: 545px) {
    padding-left: unset;
    width: 100%;
    flex-direction: row-reverse;
    gap: unset;
  }
}
.wrapper {
  display: flex;
  width: 780px;
  padding: 40px 32px;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  border-radius: 40px;
  background: var(--Primary-Grey, #f5f4f8);
  box-sizing: border-box;
  @media screen and (max-width: 545px) {
    width: unset;

    &.onError {
      border-radius: 24px;
      padding-bottom: 30px;
    }
  }
}

.inputsWrapper {
  width: 100%;
  box-sizing: border-box;
}

.inputsWrapper {
  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 40px;
  @media screen and (max-width: 545px) {
    gap: 32px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 31px;
}
