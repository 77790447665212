.title {
  font-size: 48px;
  font-family: var(--font-700);

  line-height: 48.5px;
  letter-spacing: 0em;
  max-width: 522px;
  color: #0a3f48;
  text-align: left;

  @media screen and (max-width: 545px) {
    color: var(--Primary-Secondary-Dark-Green, #0a3f48);
    text-align: center;

    font-size: 28px;
    max-width: 335px;
    line-height: 34px; /* 121.429% */
  }
}

.wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 66px;
  padding: 50px 218px;
  background-color: #f5f4f8;
  @media screen and (max-width: 545px) {
    flex-direction: column-reverse;
    padding: 50px;
    padding-left: 24px;
    padding-right: 24px;
    gap: 38px;
    padding-bottom: 88px;
  }
}

.image {
  width: 466px;
  height: 414px;
  opacity: 0;
  @media screen and (max-width: 545px) {
    display: none;
  }
}
.imageMobile {
  display: none;

  @media screen and (max-width: 545px) {
    display: unset;
    width: 342px;
    height: 283.02px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 545px) {
    gap: 16px;
  }
}

.text {
  font-size: 20px;

  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  font-family: var(--font-400);
  max-width: 463px;
  padding-bottom: 40px;

  @media screen and (max-width: 545px) {
    color: var(--Primary-Black, #000);
    text-align: center;

    font-size: 20px;

    font-weight: 400;
    line-height: 26px; /* 130% */
    padding-bottom: 32px;
  }
}
