.title {
  font-size: 28px;

  line-height: 37px;
  letter-spacing: 0em;
  text-align: center;

  font-family: var(--font-700);
}

.text {
  font-size: 20px;

  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;

  font-family: var(--font-400);

  max-width: 440px;
}

.images {
  display: flex;
  flex-direction: row;
  gap: 56px;
  justify-content: space-between;
}

.wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
  @media screen and (max-width: 545px) {
    padding-bottom: unset;
  }
}
.buttonWrapper {
  padding-right: 24px;
  padding-left: 24px;
}
.image {
  width: 440px;
}

.topTitle {
  font-size: 48px;

  line-height: 49px;
  font-family: var(--font-700);
  letter-spacing: 0em;
  text-align: left;
  color: #0a3f48;
  text-align: center;
  padding-bottom: 56px;
}
.wrapper {
}
